import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ColumnDef, SortingState, VisibilityState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box, Divider, Link,
  // List, ListItem, ListItemIcon, ListItemText, TextField, Button, Grid2,
  Tooltip, Typography,
} from '@mui/material';
// import { Remove } from '@mui/icons-material';
// import useSearchQuery from '../../../../hooks/useSearchQuery.js';
import {
  CPproQueries,
  // MRPQueries,
} from '../../../../queries/index.js';
import type { DepartmentStock } from '../../../../../CPpro/model/model.js';
import { ProductPropertyType } from '../../../../../CPpro/model/model.js';
import { removeNulls } from '../../../../../CPpro/Utils/tools.js';
// import LoadingButton from '../../../common/buttons/LoadingButton.js';
import GenericTable from '../../../table/GenericTable.js';
import type { ProductStock } from './MRPStockOverviewTypes.js';
import MRPStockOverviewFilter from './MRPStockOverviewFilter.js';

const productStockColumnHelper = createColumnHelper<ProductStock>();
const productStockColumns = [
  productStockColumnHelper.accessor('name', {
    header: 'Name',
    id: 'name',
    cell: (info) => (
      <Tooltip title={info.row.original.description}>
        <Typography>{info.getValue()}</Typography>
      </Tooltip>
    ),
  }),
  productStockColumnHelper.accessor('description', {
    header: 'Beskrivelse',
    id: 'description',
  }),
  productStockColumnHelper.accessor('itemNumber', {
    header: 'MRP Prodnr',
    id: 'itemNumber',
    enableGlobalFilter: false,
  }),
  productStockColumnHelper.accessor('productLink', {
    header: 'Lenke',
    id: 'productLink',
    enableGlobalFilter: false,
    cell: (info) => (info.getValue() ? <Link href={info.getValue()} target="_blank" rel="noreferrer noopener">{info.getValue().toLowerCase().endsWith('pdf') ? 'PDF' : 'Lenke'}</Link> : null),
  }),
  productStockColumnHelper.accessor('category', {
    header: 'Kategori',
    id: 'category',
    enableGlobalFilter: false,
  }),
  productStockColumnHelper.accessor('subCategory', {
    header: 'Underkategori',
    id: 'subCategory',
    enableGlobalFilter: false,
  }),
  productStockColumnHelper.accessor('total', {
    header: 'Totalt',
    id: 'total',
    enableGlobalFilter: false,
  }),
] as ColumnDef<ProductStock>[];

// const defaultData = undefined as string | undefined;

const initialSortingState: SortingState = [{ id: 'name', desc: false }];
const initialColumnVisibility: VisibilityState = { description: false };

const MRPStockOverview = () => {
  // const [itemNumber, setItemNumber] = useState<string>('');

  const { data: products } = useQuery({ ...CPproQueries.productsQuery });
  // const { queryData: searchData, refetch } = useSearchQuery(MRPQueries.getMRPStockQuery, defaultData);

  const { productStock, departments } = useMemo<{ productStock: ProductStock[], departments: string[] }>(() => {
    if (!products) return { productStock: [], departments: [] };

    const departmentSet = new Set<string>();

    const mapped = products.map((prod) => {
      const stockMap = new Map<string, number>();
      prod.properties
        .filter((p) => p.type === ProductPropertyType.DepartmentStock)
        .forEach((p) => {
          try {
            const depStock = JSON.parse(p.value.toString()) as DepartmentStock;
            stockMap.set(depStock.department, depStock.stock);
            departmentSet.add(depStock.department);
          } catch (err) {
            // Skip
          }
        });

      const erpProdId = prod.properties.find((p) => p.type === ProductPropertyType.MRP24SOProdid)?.value.toString();
      if (!erpProdId) return undefined;

      return {
        name: prod.properties.find((p) => p.type === ProductPropertyType.Name)?.value.toString() ?? '',
        itemNumber: erpProdId,
        stock: stockMap,
        total: Number(prod.properties.find((p) => p.type === ProductPropertyType.Stocklevel)?.value ?? 0),
        productLink: prod.properties.find((p) => p.type === ProductPropertyType.ProductSheet)?.value.toString() ?? '',
        category: prod.properties.find((p) => p.type === ProductPropertyType.ProductType)?.value.toString() ?? '',
        subCategory: prod.properties.find((p) => p.type === ProductPropertyType.SubCategory)?.value.toString() ?? '',
        description: prod.properties.find((p) => p.type === ProductPropertyType.Description)?.value.toString() ?? '',
      } satisfies ProductStock;
    }).filter(removeNulls);

    return {
      productStock: mapped,
      departments: departmentSet.values().toArray().toSorted((a, b) => a.localeCompare(b)),
    };
  }, [products]);

  const dynamicColumns = useMemo<ColumnDef<ProductStock>[]>(() => departments.map((dep) => productStockColumnHelper.display({
    id: dep,
    header: dep,
    cell: ({ row }) => row.original.stock.get(dep) ?? 0,
    enableGlobalFilter: false,
    meta: { department: true },
  })).filter(removeNulls), [departments]);

  return (
    <Box>
      <Typography variant="h2" gutterBottom>Masterplan Lageroversikt</Typography>
      <Typography variant="h5" gutterBottom>Oversikten viser tilgjengelig lager per avdeling, beregnet som antall på lager minus antall i ordre.</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      {/* <Typography variant="h4" gutterBottom>Søk direkte i Masterplan</Typography>
      <Typography variant="h6" gutterBottom>Henter nyeste info direkte fra Masterplan og ikke fra CPPro som kan vere utdatert til neste sync. Fungerer ikke alltid.</Typography>
      <Grid2 container spacing={2} sx={{ marginBottom: 2 }} alignItems="center">
        <Grid2>
          <TextField
            label="Søk med ERP produkt ID"
            variant="outlined"
            title="Søk med ERP produkt ID"
            value={itemNumber}
            onChange={(e) => setItemNumber(e.target.value)}
          />
        </Grid2>
        <Grid2>
          <LoadingButton buttonProps={{
            loading: searchData.isLoading,
            variant: 'contained',
            disabled: !itemNumber,
            onClick: () => refetch(itemNumber),
            color: 'success',
          }}
          >
            Søk
          </LoadingButton>
        </Grid2>
        <Grid2>
          <Button
            onClick={() => {
              setItemNumber('');
              refetch(undefined);
            }}
            variant="contained"
            disabled={!itemNumber}
            color="warning"
          >
            Reset søk
          </Button>
        </Grid2>
      </Grid2>
      {searchData.data ? (
        <List>
          {searchData.data?.map((item) => (
            <ListItem key={item.Warehouse}>
              <ListItemIcon>
                <Remove />
              </ListItemIcon>
              <ListItemText>
                {item.Warehouse}
                {' - '}
                {item.AvailableNew}
              </ListItemText>
            </ListItem>
          ))}
        </List>

      ) : null}
      <Divider sx={{ marginBottom: 2, marginTop: 2 }} /> */}
      {/* <Typography variant="h4" gutterBottom>Alle produkter</Typography> */}
      <GenericTable
        columns={productStockColumns.concat(dynamicColumns)}
        data={productStock}
        id="stockOverview"
        pagination
        Filter={MRPStockOverviewFilter}
        defaultPageSize={100}
        initialSortingState={initialSortingState}
        initialColumnVisibility={initialColumnVisibility}
      />
    </Box>
  );
};

export default MRPStockOverview;
